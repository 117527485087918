import * as THREE from 'three'
import ReactDOM from 'react-dom'
import React, { useCallback, useEffect, useRef, useMemo } from 'react'
import { Canvas, extend, useFrame, useThree, useRender } from 'react-three-fiber'

import TouchTexture from './TouchTexture'
import { useDrag } from "react-use-gesture"
import { EffectPass, EffectComposer, RenderPass} from 'postprocessing'
import { WaterEffect } from './WaterEffect'




extend({ EffectComposer, RenderPass, EffectPass})
//make a new context


function Image({ url, opacity, scale, position,  ...props }) {
  const texture = useMemo(() => new THREE.TextureLoader().load(url), [url])
  const { gl, aspect, camera } = useThree()
  gl.setPixelRatio(window.devicePixelRatio)
  const vFOV = camera.fov * Math.PI / 180;
  var height = 2 * Math.tan( vFOV / 2 ) * Math.abs(450)

  var width = height * (aspect)

  //var vFOV = THREE.Math.degToRad( camera.fov )
  var scaleX = 50 + width * 0.5

  var scaleY = scaleX * 0.1786
  var xFOV = (- width / 2) + (width*0.095) + (scaleX / 2)

  texture.anisotropy = gl.capabilities.getMaxAnisotropy()

  return (
    <sprite {...props} scale={[scaleX, scaleY, 1]} position={[xFOV,0 ,-450]}>
      <spriteMaterial attach="material" map={texture} />
    </sprite>
  )
}

//  <planeBufferGeometry attach="geometry" args={[5, 5]} />
function Effecting(mouse)  {
  const touchTexture = new TouchTexture();
  touchTexture.initTexture()
  const { scene, gl, size, camera } = useThree()



  const composer = useMemo(() => {

  const composer = new EffectComposer(gl);
  //const waterEffect = new WaterEffect({ texture: touchTexture.texture });
  const renderPass = new RenderPass(scene, camera);
  renderPass.renderToScreen = false;

  const effectPass = new EffectPass(camera, new WaterEffect({ texture: touchTexture.texture }));
  effectPass.renderToScreen = true;


  composer.addPass(renderPass);
  composer.addPass(effectPass);

  return composer
}, [camera, gl, scene, touchTexture.texture])





  useEffect(() => composer.dispose, [])
  useFrame(() => touchTexture.update(), 0)
  useFrame(() => touchTexture.addTouch(mouse), 0)


  useEffect(() => {void gl.setSize(size.width, size.height);void composer.setSize(size.width, size.height)}, [size, composer, gl])

  useRender(() => composer.render(), true)
  //useFrame((state, delta) => composer.render(delta), 1)


  return null
}


function App() {
  const mouser = useRef([0, 0])
  const onMouseMover = useCallback(({ clientX: x, clientY: y }) => (mouser.current = [x / window.innerWidth, 1 - (y / window.innerHeight)]), [])
  const bind = useDrag(({xy: [x,y]}) => (mouser.current = [x / window.innerWidth, 1 - (y / window.innerHeight)]), [])


  //useCallback(({ targetTouches[0].clientX: x, targetTouches[0].clientY: y}) => (mouser.current = [x / window.innerWidth, 1 - (y / window.innerHeight)]), [])
  return (
  <>
    <div style={{ width: '100%', height: '100%' }} onMouseMove={onMouseMover} {...bind()}>
      <Canvas camera={{ fov: 45, position: [0, 0, 0], near: 0.1, far: 1000 }}>
        <Effecting mouse={mouser} />
        <Image url={'/two_coats_logo_dist.png'} opacity={0} />
      </Canvas>
    </div>
    <div style={{position:'absolute', top:'calc(50% + 100px)'}}>
      <div style={{display:'flex', flexDirection:'column', paddingLeft:'9.8vw', zIndex:'40'}}>
        <a style={{color:'white', fontFamily:'Atak', fontStyle:'normal', fontSize:'calc(1.8vw + 8px)', marginBottom:'1vw', textDecoration:'none'}} href={"tel:+447961606297"}>+44 (0) 796 160 6297</a>
        <a style={{color:'white', fontFamily:'Atak', fontStyle:'normal', fontSize:'calc(1.4vw + 6px)'}} href={"mailto:jamie@twocoats.co"}>jamie@twocoats.co</a>
      </div>
    </div>
    </>
  )

}


export default App
