import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import TwoCoatsLogo from "../components/branding/TwoCoatsLogo/TwoCoatsLogo"
import SEO from "../components/seo"
import App from"../components/waterEffect/WaterTexture"

const IndexPage = () => (
  <Layout>
    <SEO title="London Based Digitial Agency" />

    <div style={{width:'100%',height:'100vh',backgroundColor:'#e258f6', position:'relative'}}>

      <App />

    </div>
  </Layout>
)

export default IndexPage
